import React from 'react';
import { Link } from 'gatsby';
import { Feature } from './../../elements/SectionElements';

const FeaturedArticle = ({title, slug, section, featuredImage, date, synopsis}) => {
    slug = `/${section.toLowerCase()}/${slug}`;

    return (
        <Feature>
            <div className="featureImage" style={{backgroundImage: `url("https:${featuredImage.file.url}")`}}>
                <div>
                    <Link className="btn" to={section.toLowerCase()}>{section}</Link>
                    <span>{date}</span>
                    <h3><Link to={slug}>{title}</Link></h3>
                <p>{synopsis}</p>    
                </div>
            </div>
        </Feature>
    )
   
}

export default FeaturedArticle;