import React from 'react';
import { Link } from 'gatsby';
import { ArticleBlock } from './../../elements/SectionElements';

const Article = ({title, slug, section, featuredImage, date, synopsis}) => {

    slug = `/${section.toLowerCase()}/${slug}`;

    return (
        <ArticleBlock>
            <Link to={slug} className="featureImage" style={{backgroundImage: `url("https:${featuredImage.file.url}")`}}>
            </Link>
            <div>
                <Link className="btn" to={section.toLowerCase()}>{section}</Link>
                <span>{date}</span>
                <h3><Link to={slug}>{title}</Link></h3>
                <p>{synopsis}</p>    
            </div>
        </ArticleBlock>
    )

}

export default Article;