import React from "react"
import Layout from './../components/shared/Layout';
import SectionArticles from './../components/section/SectionArticles';

const CafePage = () => (
  <Layout title="Cafe Reviews">
      <SectionArticles section="Cafe" description="Cafe Living Cafe Reviews" title="Cafe Reviews"/>
  </Layout>
)

export default CafePage;
