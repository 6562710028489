import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Section, SectionTitle, Articles } from './../../elements/SectionElements';
import { Container } from './../../elements/SharedElements';
import Article from './Article';
import FeaturedArticle from './FeaturedArticle';
import SectionsHeader from './SectionsHeader';

const SectionArticles = (props) => {

    const data = useStaticQuery(graphql`

        query latestArticles {

            allContentfulArticle (
                sort: {
                    fields: date
                    order: DESC
                }
            ){
                edges {
                    node {
                        title,
                        featuredImage {
                            file {
                                url
                            }
                        }
                        slug
                        synopsis
                        date(formatString: "MMMM D, YYYY")
                        section
                        featuredArticle
                    }
                }
            }

        }

    `)

 
    return (
        <Section>
            { props.title === "Latest Reviews" && data.allContentfulArticle.edges.map((edge) => {
                if(edge.node.featuredArticle){
                    return <FeaturedArticle key={edge.node.slug} {...edge.node} />
                }

                return ""
            })}
            { props.title !== "Latest Reviews" && <SectionsHeader section={props.section}>

            </SectionsHeader>}
            <Container>
                { props.title === "Latest Reviews" && <SectionTitle>{props.title}</SectionTitle> }
                <Articles>
                    {data.allContentfulArticle.edges.map((edge) => {

                        if(props.title !== "Latest Reviews" ){

                            if(props.section === edge.node.section){
                                return <Article key={edge.node.slug} {...edge.node} />
                            }

                            return ""

                        }

                        if(edge.node.featuredArticle){
                            return ""
                        }
        
                        return <Article key={edge.node.slug} {...edge.node} />
                    })}
                </Articles>
            </Container>
        </Section>
    )
    
}

export default SectionArticles;