import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Header } from './../../elements/SectionElements';

const SectionsHeader = (props) => {

    const assets = useStaticQuery(graphql`
        query ContenfulAssest {
            allContentfulAsset {
                edges {
                    node {
                        file {
                            url
                        }
                    }
                }
            }
        }
    `)

    const backgroundImage = assets.allContentfulAsset.edges.filter((edge) => {
        return edge.node.file.url.includes(`${props.section.toLowerCase()}-background`)
    });

    return (
        <Header style={{backgroundImage: `url(${backgroundImage[0].node.file.url})`}}>
            { props.section === "Guides" ? <h1>{props.section}</h1> : <h1>{props.section} Reviews</h1> }
            <div></div>
        </Header>
    )
    
}

export default SectionsHeader;