import styled from 'styled-components';

export const Section = styled.section`
    background-color: ${ props => props.theme.lightColor};
    padding: 0 0 2rem;
`
export const SectionTitle = styled.h1`
    color: ${ props => props.theme.darkGreyColor};
    font-size: 2.2rem;
    border-bottom: 1px solid ${ props => props.theme.primaryColor};
    padding-bottom: 0.6rem;
    text-align: center;

    @media screen and (max-width: 768px){
        margin: 0 2rem;
    }
`

export const Header = styled.header`
    background-color: ${ props => props.theme.darkColor};
    opacity: 0.9;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-size: cover;
    background-position: center center;
    position: relative;
    z-index: 2;


    h1 {
        color: ${ props => props.theme.lightColor};
        font-size: 3rem;
        position: relative;
        z-index: 2;

        @media screen and (max-width: 500px){
            font-size: 2rem;
        }
    }

    div {
        width: 5rem;
        border-top: 2px solid ${ props => props.theme.primaryColor};
        content: "";
        position: relative;
        z-index: 2;
    }

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: ${ props => props.theme.darkColor};
        opacity: 0.5;
        z-index: 1;
    }
`

export const Articles = styled.div`
    padding: 1rem 0;
    display: flex;
    flex-wrap: wrap;
`

export const Feature = styled.div`

    padding: 0 0 2rem;
    border-radius: 1rem;

    .featureImage {
        height: 80vh;
        background-size: contain;
        background-position: center center;
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        position: relative;
        z-index: 2;
        background-color: ${ props => props.theme.darkColor};
        opacity: 0.9;


        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background-color: ${ props => props.theme.darkColor};
            opacity: 0.5;
            z-index: 1;
        }

        div {
            padding: 1rem;
            width: 50%;

            @media screen and (max-width: 768px){
                width: 100%;
            }

            span {
                display: block;
                color: ${ props => props.theme.lightColor};
                margin-top: 0.6rem;
                position: relative;
                z-index: 2;
                font-size: 1.5rem;
            }

            .btn {
                padding: 0.2rem 0.8rem 0.4rem;
                background-color: ${ props => props.theme.primaryColor};
                color: ${ props => props.theme.secondaryColor};
                display: inline-block;
                border-radius: 0.5rem;
                transition: all 0.5s ease-in-out;
                position: relative;
                z-index: 2;
                font-size: 1.5rem;


            }

            h3 {
                a{
                    color: ${ props => props.theme.primaryColor};
                    font-weight: bold;
                    font-size: 3rem;
                    transition: all 0.5s ease-in-out;
                    position: relative;
                    z-index: 2;
                    @media screen and (max-width: 500px){
                        font-size: 2.5rem;
                    }
                }
                margin-top: 0.6rem;
            }

            p {
                margin-top: 0.6rem;
                color: ${ props => props.theme.lightColor};
                position: relative;
                z-index: 2;
                font-size: 1.5rem;

                @media screen and (max-width: 500px){
                    font-size: 1.1rem;
                }
            }
        }
    }

`

export const ArticleBlock = styled.div`
    padding: 2rem 1rem;
    flex-basis: 48%;
    background-color: ${ props => props.theme.secondaryColor};
    margin-left: 1rem;
    margin-bottom: 1rem;

    @media screen and (max-width: 768px){
        flex-basis: 45%;
    }

    @media screen and (max-width: 500px){
        flex-basis: 98%;
    }

    .featureImage {
        height: 20rem;
        background-size: cover;
        border-radius: 1rem;
        background-position: center center;
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;

    }

    div {
        span {
            display: block;
            color: ${ props => props.theme.darkGreyColor};
            margin-top: 0.6rem;
        }

        .btn {
            padding: 0.2rem 0.8rem 0.4rem;
            background-color: ${ props => props.theme.primaryColor};
            color: ${ props => props.theme.secondaryColor};
            display: inline-block;
            margin-top: 0.6rem;
            border-radius: 0.5rem;
        }

        h3 {
            a{
                color: ${ props => props.theme.primaryColor};
                font-weight: bold;
                font-size: 1.8rem;
                transition: all 0.5s ease-in-out;
            }
            margin-top: 0.6rem;
        }

        p {
            margin-top: 0.6rem;
            font-weight: 300;
            color: ${ props => props.theme.darkColor};
        }
    }

`